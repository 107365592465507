<template lang="pug">
div 
	transition(name="fadeLoading")
		Loading(v-show="isActiveLoading")
	router-view
	Footer
</template>
<script>
import { nextTick } from "vue";
import Loading from '@/components/Loading'
import Footer from '@/components/Footer'

export default {
	data(){
		return{
			isActiveLoading: true
		}
	},
	components: {
		Loading,
		Footer
	},
	watch: {
		$route(to) {
			//依據品牌替換主色
			if(to.fullPath.indexOf('tiger-q1')!==-1){
				document.documentElement.style.setProperty('--bgMoLinear', 'linear-gradient(to bottom, #01316b 67%, #033885 2%)');
				document.documentElement.style.setProperty('--bgPcLinear', 'linear-gradient(to top, #01306a, #022251)');
			}else if(to.fullPath.indexOf('heineken0-0-q1')!==-1){
				document.documentElement.style.setProperty('--bgMoLinear', 'linear-gradient(to top, #14477F, #14477F)');
				document.documentElement.style.setProperty('--bgPcLinear', 'linear-gradient(to top, #0084cc, #033a87)');
			}else if(to.fullPath.indexOf('tiger-q2')!==-1){
				document.documentElement.style.setProperty('--bgMoLinear', 'linear-gradient(to top, #f46500, #ec5d00)');
				document.documentElement.style.setProperty('--bgPcLinear', 'linear-gradient(to top, #0b41af, #032770)');
			}else if(to.fullPath.indexOf('edelweiss-q2')!==-1){
				document.documentElement.style.setProperty('--bgMoLinear', 'linear-gradient(to top, #fff, #fff)');
				document.documentElement.style.setProperty('--bgPcLinear', 'linear-gradient(to top, #122545, #0842af)');
			}else if(to.fullPath.indexOf('tiger-q3')!==-1){ 
				document.documentElement.style.setProperty('--bgMoLinear', 'linear-gradient(to top, #021941, #021941');
				document.documentElement.style.setProperty('--bgPcLinear', 'linear-gradient(to top, #0949b7, #1a2f56)');
			}else if(to.fullPath.indexOf('heineken-cvs-q3')!==-1){ 
				document.documentElement.style.setProperty('--bgMoLinear', 'linear-gradient(to top, #0b8014, #004506');
				document.documentElement.style.setProperty('--bgPcLinear', 'linear-gradient(to top, #0b8014, #004506)');
			}else if(to.fullPath.indexOf('heineken-superfast-q4')!==-1){
				document.documentElement.style.setProperty('--bgMoLinear', 'linear-gradient(to top, #ffffff, #ffffff');
				document.documentElement.style.setProperty('--bgPcLinear', 'linear-gradient(to top, #048431, #23ac39)');
			}else if(to.fullPath.indexOf('tiger-25-q1')!==-1){
				document.documentElement.style.setProperty('--bgMoLinear', 'linear-gradient(to bottom, #01316b 67%, #033885 2%)');
				document.documentElement.style.setProperty('--bgPcLinear', 'linear-gradient(to top, #01306a, #022251)');
			}
		},

		
	},
	mounted() {
		nextTick(() => {
			let vm=this

			//依品牌替換title/description
			if(location.href.indexOf('tiger-q1')!==-1){
				document.title='虎牌啤酒熱情開燥整年龍吼啦'
				document.getElementsByTagName('meta')["description"].content = "2024/1/24-2/29指定通路，買虎牌全系列商品，登錄發票抽新加坡雙人套裝行程";
			}else if(location.href.indexOf('heineken0-0-q1')!==-1){
				document.title='喝海尼根0.0零酒精 隨時乾杯都可以'
				document.getElementsByTagName('meta')["description"].content = "2024/1/24-2/29指定通路，買海尼根0.0零酒精，登錄發票抽PS5極限飆速組、1:10RC遙控賽車組";
			}else if(location.href.indexOf('tiger-q2')!==-1){
				document.title='熱情燥出來'
				document.getElementsByTagName('meta')["description"].content = "2024/3/6-4/30至指定通路，購買虎牌啤酒全系列，登錄發票就抽Garmin手錶、Wilson FIBA籃球";
			}else if(location.href.indexOf('edelweiss-q2')!==-1){
				document.title='艾德懷斯啤酒頂級奢華體驗'
				document.getElementsByTagName('meta')["description"].content = "2024/3/15-5/15至指定通路，購買艾德懷斯啤酒全系列，輸入序號就抽洲際住宿!!!";
			}else if(location.href.indexOf('tiger-q3')!==-1){ 
				document.title='虎牌啤酒 抽高雄全家海神周邊商品'
				document.getElementsByTagName('meta')["description"].content = "2024/4/17 - 5/14至指定通路，購買虎牌啤酒全系列，登錄發票就抽高雄全家海神隊周邊商品!!!";
			}else if(location.href.indexOf('heineken-cvs-q3')!==-1){ 
				document.title='下班就是要海尼根！'
				document.getElementsByTagName('meta')["description"].content = "2024/5/1 - 5/28至指定通路，購買海尼根全系列任三罐啤酒，登錄發票就抽海尼根周邊商品!!!";
			}else if(location.href.indexOf('heineken-superfast-q4')!==-1){ 
				document.title='海尼根啤酒 登錄發票抽SuperFast海尼根配色電單車'
				document.getElementsByTagName('meta')["description"].content = "2024/6/1 -7/31 至指定通路，購買海尼根全系列啤酒任一組，登錄發票就抽SuperFast海尼根配色電單車!!!";
			}else if(location.href.indexOf('tiger-25-q1')!==-1){
				document.title='Tiger福虎生風'
				document.getElementsByTagName('meta')["description"].content = "2025/1/1-3/31至指定通路，購買虎牌系列啤酒滿$159，登錄發票抽新加坡雙人來回機票";
			}

			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
						
			setTimeout(() => {
				vm.isActiveLoading=false
			}, 1500);
		});	
	},
}
</script>
<style lang="sass">
@import "@/assets/sass/_reset.sass"
@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
@import "@/assets/sass/_layout.sass"
</style>