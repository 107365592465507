<script>
import { useMeta } from 'vue-meta'
import Menu from '@/components/Tiger_25_Q1/Menu'

export default {
	name: "Tiger25Q1_info",
	data() {
		return {
		};
	},
	setup () {
		useMeta(
		{ 
			title: 'Tiger福虎生風',
			description: '2025/1/1-3/31至指定通路，購買虎牌系列啤酒滿$159，登錄發票抽新加坡雙人來回機票' 
		})		
	},
	components: {
		Menu
	},
	computed: {
	},
	watch: {
	},
	mounted() {
	},
	methods: {
	},
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
